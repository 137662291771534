import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import NProgress from 'nprogress';
import Layout from '../components/layout/layout';
import { allTrialsIds } from '../constants/config';
import { Fonts, Container, SitemapPage } from '../global.css';

const Sitemap = ({ data }) => {
  const [scriptsLoading, setScriptsLoading] = useState(true);
  const [urls, setUrls] = useState(data.allStudyIds.nodes);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScriptsLoading(false);
    }
    loadData();
  }, []);

  const loadData = () => {
    NProgress.start();
    axios
      .get(allTrialsIds())
      .then((response) => {
        NProgress.done();
        if (response.data && response.data.Success) {
          setUrls(response.data.Data);
        }
      })
      .catch(() => {
        NProgress.done();
      });
  };

  return scriptsLoading ? (
    ''
  ) : (
    <Layout
      meta={{
        pageTitle: 'Sitemap',
        pageDescription: 'Full website sitemap here',
      }}
    >
      <Container>
        <Fonts>
          <SitemapPage>
            <h1>Sitemap</h1>
            <ul>
              {data.pageMetadataJson.pages
                .filter((page) => page.url.indexOf('sitemap') < 0)
                .map((url) => (
                  <li key={url.url}>
                    {url.url.indexOf('http') === 0 ? (
                      <a
                        href={url.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url.menuTitle}
                      </a>
                    ) : (
                      <a href={`/${url.pageLang}/${url.url}`}>
                        {url.menuTitle}
                      </a>
                    )}
                  </li>
                ))}
              {urls.map((url) => (
                <li key={url.Id}>
                  <a href={`/en/study/${url.UniqueIdentifier}`}>
                    {'Trial Details Page, ID number: ' + url.UniqueIdentifier}
                  </a>
                </li>
              ))}
            </ul>
          </SitemapPage>
        </Fonts>
      </Container>
    </Layout>
  );
};

Sitemap.propTypes = {
  data: PropTypes.object.isRequired,
};

const SitemapWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
        allStudyIds(limit: 9999) {
          nodes {
            UniqueIdentifier
            Id
          }
        }
      }
    `}
    render={(data) => <Sitemap data={data} {...props} />}
  />
);

export default SitemapWithQuery;
